

















































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  private cTab = "脉图时域绝对值";
  private data: any = {
    脉图时域绝对值: [
      {
        字段名称: "主波幅度",
        字段英文名称: "h1",
        字段释义:
          "为主波峰顶到脉搏波图基线的高度(基线与时间轴平行时)。主要反映左心室的射血功能和大动脉的顺应性，即左心室收缩力强，大动脉顺应性好的状态下，h1高大，反之则小。",
      },
      {
        字段名称: "主波峡幅度",
        字段英文名称: "h2",
        字段释义:
          "是主波与重搏前波之间的一个低谷的幅度。其生理意义与h3一致，脉图分析时往往可略去。",
      },
      {
        字段名称: "重搏前波幅度",
        字段英文名称: "h3",
        字段释义:
          "为重搏前波峰顶到脉搏波图基线的高度。主要反映动脉血管弹性和外周阻力状态。如动脉血管由于管壁张力高，或者硬化，或者外周阻力增高时，均可引起h3幅度增高。重搏前波抬高一般伴随其时相的提前，反映了动脉血管高张力、高阻力状态时，脉搏反射波传导速度的增快。",
      },
      {
        字段名称: "降中峡幅度",
        字段英文名称: "h4",
        字段释义:
          "为降中峡谷底到脉搏波图基线的高度。降中峡高度与舒张压相应。主要与动脉血管外周阻力、主动脉瓣关闭功能有关，外周阻力增加时，表现为h4增加；反之降低。",
      },
      {
        字段名称: "重搏波幅度",
        字段英文名称: "h5",
        字段释义:
          "为重搏波峰顶到降中峡谷底所作的基线平行线之间的高度。重搏波幅度主要反映大动脉的弹性(顺应性)和主动脉瓣功能情况，当大动脉顺应性降低时，h5减少，或者主动脉瓣硬化、闭锁不全时h5可以为0(重搏波峰顶与降中峡谷底同一水平)，甚至出现负值(重搏波峰顶低于降中峡谷底水平)。",
      },
      {
        字段名称: "",
        字段英文名称: "t1",
        字段释义: "为脉图起点到主波峰点的时值。t1对应于左心室的快速射血期。",
      },
      {
        字段名称: "",
        字段英文名称: "t2",
        字段释义: "为脉图起始点到主波峡之间的时值。",
      },
      {
        字段名称: "",
        字段英文名称: "t3",
        字段释义: "为脉图起始点到重搏前波之间的时值。",
      },
      {
        字段名称: "",
        字段英文名称: "t4",
        字段释义: "为脉图起始点到降中峡之间的时值。t4对应于左心室的收缩期。",
      },
      {
        字段名称: "",
        字段英文名称: "t5",
        字段释义: "为降中峡到脉图终止点之间的时值。t5对应于左心室的舒张期。",
      },
      {
        字段名称: "",
        字段英文名称: "t",
        字段释义:
          "为脉图起始点到终止点的时值。对应于左心室的一个心动周期，亦称为脉动周期。但当心房颤动、或期外收缩时，脉图与心电图的心动周期不完全一致。",
      },
      {
        字段名称: "",
        字段英文名称: "W1",
        字段释义:
          "将主波从脉图基线到峰顶高度（h1）之间划分三段，W1 为上1/3处的脉图宽度。相当于动脉内压力高水平状态所维持的时间。",
      },
      {
        字段名称: "",
        字段英文名称: "W2",
        字段释义:
          "将主波从脉图基线到峰顶高度（h1）之间划分五段，W2为上1/5处的脉图宽度。意义同W1相当，在实际使用中一般选用W1较多。有时在测量W1、W2时，如刚巧其中一个逢脉图曲线拐点或切痕而无法精确测量，则可选用另一个测算。",
      },
      {
        字段名称: "",
        字段英文名称: "As",
        字段释义: "为收缩期面积。脉图面积与心排出量有关。",
      },
      {
        字段名称: "",
        字段英文名称: "Ad",
        字段释义: "为舒张期面积。",
      },
      {
        字段名称: "",
        字段英文名称: "A",
        字段释义: "为收缩期与舒张期脉图面积之和。",
      },
      {
        字段名称: "",
        字段英文名称: "P",
        字段释义:
          "取脉压力值，25克力/mm。（换能器对皮肤软组织施加的压力，相当于手指切脉时的指力）。",
      },
    ],
    脉图时域相对值: [
      {
        字段名称: "",
        字段英文名称: "h3/h1",
        字段释义:
          "主要反映血管壁的顺应性和外周阻力。血管顺应性差，外周反射波折返速度快时，重搏前波出现早，h3/h1就会增高。",
      },
      {
        字段名称: "",
        字段英文名称: "h4/h1",
        字段释义:
          "主要反映外周阻力高低。当外周血管收缩时，阻力增高，h4/h1升高(>0.45)；反之，外周阻力降低时，h4/h1变小(<0.30)。在主动脉瓣闭锁不全，血液返流，脉压增大时；或血容积量急骤下降，心搏代偿性加快时，亦可出现h4/h1变小，甚至出现负值(参考孔脉图)。多见于中医辨证的气虚、血虚证。",
      },
      {
        字段名称: "",
        字段英文名称: "h5/h1",
        字段释义:
          "主要反映主动脉顺应性和主动脉瓣功能情况。当动脉顺应性差，或主动脉瓣闭锁不全时h5/h1等于0，甚至为负值。反之，主动脉瓣功能正常，动脉顺应性佳，血容积量充盈时h5/h1增高。",
      },
      {
        字段名称: "",
        字段英文名称: "W1/t",
        字段释义:
          "对应于主动脉压力升高的持续时间，与h3的出现时间和外周阻力有关。动脉弹性差时，重搏前波出现早，与主波接近或叠加，或外周阻力增加，h3增高等均可出现高大主波，使W1/t增高；反之，外周阻力小，或血容量不足情况下h3接近降中峡，主峰波变窄，W1/t变小。",
      },
      {
        字段名称: "",
        字段英文名称: "W2/t",
        字段释义: "与W1/t意义相当，随不同人、不同区域喜欢而选用。",
      },
      {
        字段名称: "",
        字段英文名称: "t1/t",
        字段释义:
          "与心脏射血功能有关。当左心室收缩功能减退，射血速率减少时，t1/t延长。",
      },
      {
        字段名称: "",
        字段英文名称: "t5/t4",
        字段释义: "与心率有关。心率加快时t5/t4>1。在中医辨证中多见于阴虚火旺。",
      },
    ],
  };
}
